<template>
	<div class="app-container" v-loading="loading">
		<div class="titlebox">
			<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>

			<div class="title">员工删除记录</div>
		</div>
		<section style="background: rgb(255, 255, 255); padding: 20px 20px 10px; overflow: hidden">
			<el-row style="width: 100%; margin-top: 15px">
				<el-col :span="4">
					<div class="inputBox">
						<span>姓名</span>
						<el-input v-model="params.name" size="small" style="width: 180px"></el-input>
					</div>
				</el-col>
				<el-col :span="4">
					<div class="inputBox">
						<span>手机号</span>
						<el-input v-model="params.phoneNumber" size="small" style="width: 180px"></el-input>
					</div>
				</el-col>
				<!--<el-col :span="9">
        <div class="inputBox">
          <span>删除时间</span>
          <el-date-picker
            v-model="params.startTime"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            type="date"
            size="small"
            @change="startChange"
            style="margin-right:5px;width:250px"
          ></el-date-picker
          >-
          <el-date-picker
            v-model="params.endTime"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            type="date"
            size="small"
            @change="endChange"
            style="margin-left:5px;width:250px"
          ></el-date-picker>
        </div>
      </el-col>!-->
				<el-col :span="5">
					<el-button type="primary" size="small" icon="el-icon-search" style="height: 32px; margin-left: 10px" @click="handleSearch()"
						>查询</el-button
					>
				</el-col>
			</el-row>
			<el-table :data="records" stripe class="staff staff-table tableBackground" v-loading="loading" style="width: 100%; margin-top: 20px">
				<el-table-column type="index" align="center" label="序号" width="60"></el-table-column>
				<el-table-column
					v-for="col in columns"
					:prop="col.prop"
					:key="col.id"
					align="center"
					:label="col.name"
					:width="col.width"
				></el-table-column>
			</el-table>
			<el-pagination
				class="fr"
				style="margin: 20px 0"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="pagenation.pageNo"
				:page-sizes="[20, 50, 100, 200, 300, 400]"
				:page-size="pagenation.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="pagenation.totalNum"
			></el-pagination>
		</section>
	</div>
</template>

<script>
import { deleteStaffRecord } from '../../../api/workbench';
export default {
	data() {
		return {
			pagenation: {
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			loading: false,
			params: {
				name: '',
				phoneNumber: '',
				startTime: '',
				endTime: ''
			},
			columns: [
				{ name: '姓名', values: 'name', id: '2', prop: 'name' },
				{ name: '工号', values: 'jobNumber', id: '3', prop: 'jobNumber' },
				{ name: '性别', values: 'sexName', id: '4', prop: 'sexName' },
				{ name: '职位', values: 'officeName', id: '16', prop: 'officeName' },
				{
					name: '入职日期',
					values: 'entryTime',
					id: '19',
					prop: 'entryTime',
					width: '115'
				},
				{
					name: '手机号码',
					values: 'phoneNumber',
					id: '20',
					prop: 'phoneNumber',
					width: '115'
				},
				{
					name: '工作邮箱',
					values: 'mailbox',
					id: '21',
					prop: 'mailbox',
					width: '200'
				}
			],
			records: []
		};
	},
	mounted() {
		this.handleSearch();
	},
	methods: {
		startChange(val) {
			this.params.startTime = `${val} 00:00:00`;
		},
		endChange(val) {
			this.params.endTime = `${val} 23:59:59`;
		},
		handleSearch() {
			this.loadding = true;
			const { params } = this;

			deleteStaffRecord(params).then((result) => {
				this.records = result.list;
				this.pagenation.totalNum = result.totalNum;
				this.loadding = false;
			});
		},
		handleSizeChange(val) {
			this.pagenation.pageSize = val;
			this.params.pageSize = val;
			this.handleSearch();
		},
		handleCurrentChange(val) {
			this.pagenation.pageNo = val;
			this.params.pageNo = val;
			this.handleSearch();
		}
	}
};
</script>

<style lang="scss" scoped>
.container {
	display: flex;
	flex-wrap: wrap;
}
.inputBox {
	margin-right: 0;
}
</style>
